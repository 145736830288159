/* eslint react/jsx-pascal-case: 0 */
import {Color, Font, FontMix, FontSizes, Group, UIExplorer} from '@indoqa/ui-explorer'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../app/theme'
import {useEnableBodyClassReader} from '../../commons/hooks/use-body-class-reader/useBodyClassReader'
import {UIE_ContentNodeSample} from '../components/UIE_ContentNodeSample'
import {UIE_LawContentSample} from '../components/UIE_LawContentSample'
import {UIE_SearchInput} from '../components/UIE_SearchInput'
import {UIE_ValidityDatePicker} from '../components/UIE_ValidityDatePicker'
import {uiePathOverview} from '../store/uie.paths'

const getColors = (theme: Theme): Color[] => [
  {name: 'primary', hexCode: theme.colors.primary},
  {name: 'secondary', hexCode: theme.colors.secondary},
  {name: 'accent', hexCode: theme.colors.accent},
  {name: 'contentDocument-emphasized', hexCode: theme.colors.bgContentEmphasised},
  {name: 'text', hexCode: theme.colors.text},
]

const getTextFonts = (theme: Theme): Font[] => [{name: 'base', fontStyle: theme.fontStyles.base}]

const getHeadlineFonts = (theme: Theme): Font[] => [{name: 'alternative', fontStyle: theme.fontStyles.alt}]

const getFontSizes = (theme: Theme): FontSizes => [
  theme.fontSizes.extraBig,
  theme.fontSizes.big,
  theme.fontSizes.text,
  theme.fontSizes.small,
]

const getFontMixes = (theme: Theme): FontMix[] => [
  {name: 'System fonts', textFont: theme.fontStyles.base, headlineFont: theme.fontStyles.alt},
]

const getCLSContent = (): Group => ({
  name: 'Content',
  descriptions: [
    {
      name: 'CLS - Testwiese 1',
      component: <UIE_ContentNodeSample url="/api/v1/webc/documents/IDQ_SL8096520" />,
    },
    {
      name: 'CLS - Testwiese 2 - Liste',
      component: <UIE_ContentNodeSample url="/api/v1/webc/documents/SL3200481" />,
    },
    {
      name: 'RIS - Beispiel 1',
      component: <UIE_LawContentSample url="/api/v1/law/document/NOR40210693" />,
    },
    {
      name: 'RIS - Beispiel 2',
      component: <UIE_LawContentSample url="/api/v1/law/document/NOR40192938" />,
    },
    {
      name: 'RIS - Beispiel 3',
      component: <UIE_LawContentSample url="/api/v1/law/document/NOR12017705" />,
    },
    {
      name: 'RIS - Beispiel 4',
      component: <UIE_LawContentSample url="/api/v1/law/document/NOR40206206" />,
    },
  ],
})

const getStructure = (): Group => ({
  name: 'Structure',
  descriptions: [
    {
      name: 'RIS Structure',
      component: <div />,
    },
  ],
})

const getComponents = (): Group => ({
  name: 'Components',
  descriptions: [
    {
      name: 'Date-Picker',
      component: <UIE_ValidityDatePicker />,
    },
    {
      name: 'Search-Input',
      component: <UIE_SearchInput />,
    },
  ],
})

export const DigitalUIExplorer: React.FC = () => {
  const {theme} = useFela<Theme>()
  useEnableBodyClassReader()
  return (
    <UIExplorer
      colors={getColors(theme)}
      description="Overview"
      fontMixes={getFontMixes(theme)}
      fontSizes={getFontSizes(theme)}
      headlineFonts={getHeadlineFonts(theme)}
      groups={[getCLSContent(), getStructure(), getComponents()]}
      mountPath={uiePathOverview()}
      projectName="ÖGBV Style-System"
      textFonts={getTextFonts(theme)}
      textFontSize={theme.fontSizes.text}
    />
  )
}
