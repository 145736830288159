import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useDispatch} from 'react-redux'
import tippy from 'tippy.js'
import 'tippy.js/animations/shift-away.css'
import 'tippy.js/dist/backdrop.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import {ThemedIcon} from '../../../commons-shared/themed-icon/ThemedIcon'
import {SelectivePrintingContext} from '../../../kv/components/printing/SelectivePrintingContext'
import {lawPathRedirect} from '../../../law/store/law.paths'
import {
  DIGITAL_CSS_CLASSNAME,
  DIGITAL_DEBUG_CSS_CLASSNAME,
  DIGITAL_ENABLE_LAW_LINKS,
  DIGITAL_PARA_CSS_CLASSNAME,
} from '../../css-rules/DigitalCSSRules'
import {Pool} from '../../idqsa/idqsa'
import {useTrackReadSignal} from '../../idqsa/useTrackReadSignal'
import {ReaderLayoutContext, ReaderLayoutContextValue} from '../../layouts/reader-layout/ReaderLayoutContext'
import {ContentNode} from '../../types/ContentNode'
import {findScrollParent} from '../../utils/findScrollParent'
import {ReactComponent as PrinterIcon} from '../printer-icon/printer.svg'
import {ContentPanelPrintFootnotes} from './ContentPanelPrintFootnotes'
import {createFootnoteHtml} from './createFootnoteHtml'
import {handleContentPanelLink, LinkHandler} from './handleContentPanelLink'

export interface CurrentNodeId {
  id: string
}

export interface ContentPanelProps {
  contentNode: ContentNode | null
  currentNodeId?: CurrentNodeId
  disablePrinting?: boolean
  docsetLinkHandler?: LinkHandler
  enableLawLinks?: boolean
  idrefLinkHandler?: LinkHandler
  level?: number
  parentFootNotes?: Record<string, string>
  pos?: number
  topNode?: React.RefObject<HTMLDivElement>
  trackingInfo?: {
    pool: Pool
    resultItemId?: string
  }
}

type ContentPanelChildProps = Omit<ContentPanelProps, 'innerRef'>

const debug = false

const PROCESSED_ATTR = 'p'
const PROCESSED_ATTR_VALUE = 'true'

const ContentPanelChild = ({
  contentNode,
  level,
  currentNodeId,
  enableLawLinks,
  topNode,
  disablePrinting,
  parentFootNotes,
  pos,
  trackingInfo,
}: ContentPanelChildProps) => {
  if (contentNode === null) {
    return null
  }
  return (
    <ContentPanel
      key={contentNode.id}
      contentNode={contentNode}
      currentNodeId={currentNodeId}
      disablePrinting={disablePrinting}
      enableLawLinks={enableLawLinks}
      level={level}
      parentFootNotes={parentFootNotes}
      pos={pos}
      topNode={topNode}
      trackingInfo={trackingInfo}
    />
  )
}

const ContentPanelChildren = ({
  contentNode,
  level,
  currentNodeId,
  parentFootNotes,
  enableLawLinks,
  topNode,
  disablePrinting,
  trackingInfo,
}: ContentPanelChildProps) => {
  if (!contentNode || !contentNode.children) {
    return null
  }
  const renderedChildren = contentNode.children.map((childContentNode, index) => (
    <ContentPanelChild
      key={childContentNode.id}
      contentNode={childContentNode}
      currentNodeId={currentNodeId}
      level={level}
      enableLawLinks={enableLawLinks}
      parentFootNotes={parentFootNotes}
      pos={index}
      topNode={topNode}
      disablePrinting={disablePrinting}
      trackingInfo={trackingInfo}
    />
  ))
  return <>{renderedChildren}</>
}

const createClassNames = (
  contentNode: ContentNode,
  level: number,
  enableLawLinks?: boolean,
  printSelected?: boolean
) => {
  let className = DIGITAL_PARA_CSS_CLASSNAME + ' '
  if (level === 0) {
    className += DIGITAL_CSS_CLASSNAME + ' '
  }
  if (contentNode.typ) {
    className += contentNode.typ + ' '
  }
  if (contentNode.element) {
    className += contentNode.element + ' '
  }
  if (contentNode.id) {
    className += contentNode.id + ' '
  }
  if (debug) {
    className += DIGITAL_DEBUG_CSS_CLASSNAME + ' '
  }
  if (enableLawLinks) {
    className += DIGITAL_ENABLE_LAW_LINKS + ' '
  }
  if (printSelected) {
    className += 'print-selected '
  }
  className += 'xxx d-' + level
  return className
}

/**
 * This React component recursively renders a {ContentNode} to HTML. The HTML is wrapped with a div element which sets a classname.
 * For performance reasons this component does NOT register any CSS rules but expects that this is done in the {App} component
 * only once for the application at initialization time.
 */
export const ContentPanel = (props: ContentPanelProps) => {
  const {
    contentNode,
    currentNodeId,
    level,
    parentFootNotes,
    pos,
    enableLawLinks,
    topNode,
    disablePrinting,
    docsetLinkHandler,
    idrefLinkHandler,
    trackingInfo,
  } = props
  const currentLevel = level === undefined ? 0 : level + 1
  const dispatch = useDispatch()

  // contentRef
  const readerLayoutContext = React.useContext<ReaderLayoutContextValue>(ReaderLayoutContext)
  const contentRef = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (!readerLayoutContext) {
      return
    }
    readerLayoutContext.setContentRef(contentRef)
  }, [contentRef, readerLayoutContext])

  // idqsa tracking
  const pool = trackingInfo?.pool
  const resultItemId = trackingInfo?.resultItemId
  const elementCallback = React.useCallback(() => {
    if (currentLevel !== 0) {
      return null
    }
    return resultItemId ? document.getElementsByClassName(`para ${resultItemId}`)[0] : null
  }, [resultItemId, currentLevel])

  useTrackReadSignal(elementCallback, pool, resultItemId === currentNodeId?.id ? resultItemId : null)

  // printing
  const [inFocus, setInFocus] = React.useState(false)
  const selectivePrintContextState = React.useContext(SelectivePrintingContext)
  const printSelected = selectivePrintContextState?.containsParaId(contentNode?.id!)
  const setPrintSelected = (selected: boolean) => {
    preventScrolling.current = true
    if (selected) {
      selectivePrintContextState?.addParaId(contentNode?.id!)
    } else {
      selectivePrintContextState?.removeParaId(contentNode?.id!)
    }
  }
  const showPrintingIcon = !disablePrinting && (inFocus || printSelected)

  const createClassName = (baseClassName: string): string => {
    if (printSelected) {
      return `${baseClassName} print-selected`
    }
    return baseClassName
  }

  // scrolling
  const preventScrolling = React.useRef<boolean>(false)
  React.useLayoutEffect(() => {
    preventScrolling.current = false
  }, [currentNodeId?.id])

  React.useLayoutEffect(() => {
    if (!contentRef.current || !currentNodeId || !contentNode) {
      return
    }
    if (preventScrolling.current) {
      return
    }

    // currentNodeId has to be an object so that it changes with each click on a tree element
    if (contentNode.id === currentNodeId.id) {
      // if (currentLevel === 1 && pos === 0) {
      //   if (topNode && topNode.current) {
      //     console.log('scrollIntoView1')
      //     topNode.current.scrollIntoView()
      //   }
      // } else {
      contentRef.current.scrollIntoView()
      preventScrolling.current = true
      if (selectivePrintContextState?.containsAnyParaId()) {
        const parentElement = findScrollParent(contentRef.current)
        parentElement?.scrollBy({top: -22})
      }
      // console.log('after scroll contentRef into view', alreadyScrolledToCurrentNode.current)
      // }
    }
  }, [currentNodeId, contentRef, contentNode, currentLevel, pos, topNode, selectivePrintContextState])

  // link resolution
  React.useLayoutEffect(() => {
    if (contentRef.current && currentLevel === 0) {
      // idref links
      if (idrefLinkHandler) {
        const idrefLinks = contentRef.current!.querySelectorAll('a[idref]')
        idrefLinks.forEach((element) => handleContentPanelLink(idrefLinkHandler, element, 'idref'))
      }

      // docset links
      if (docsetLinkHandler) {
        const docsetElements = contentRef.current!.querySelectorAll('span[setid]')
        docsetElements.forEach((element) => {
          const parentElement = element.parentElement
          if (!parentElement) {
            return
          }
          const anchor = document.createElement('a')
          anchor.setAttribute('setid', element.getAttribute('setid') || '')
          parentElement.replaceChild(anchor, element)
          anchor.appendChild(element)

          handleContentPanelLink(docsetLinkHandler, anchor, 'setid')
        })
      }

      // law links
      if (enableLawLinks) {
        // law links (slightly different to idref links because there are two relevant parameters, hence the reimplementation)
        const lawLinks = contentRef.current!.querySelectorAll('a[data-law]')
        lawLinks.forEach((lawLink) => {
          const hasBeenProcessed = lawLink.getAttribute(PROCESSED_ATTR) === PROCESSED_ATTR_VALUE
          if (hasBeenProcessed) {
            return
          }

          const lawId = lawLink.getAttribute('data-law')
          const sectionId = lawLink.getAttribute('data-section')
          lawLink.setAttribute('tabIndex', '0')
          lawLink.setAttribute('href', '#')

          // support "open in new tab"
          lawLink.addEventListener('mouseenter', (e) => {
            if (e) {
              const target = e.currentTarget as HTMLAnchorElement
              const link = lawPathRedirect(lawId, sectionId)
              target?.setAttribute('href', link)
              target?.setAttribute('target', '_blank')
            }
          })
        })
      }

      // book links
      const bookLinks = contentRef.current!.querySelectorAll('a[data-book]')
      bookLinks.forEach((bookLink) => {
        const hasBeenProcessed = bookLink.getAttribute(PROCESSED_ATTR) === PROCESSED_ATTR_VALUE
        if (hasBeenProcessed) {
          return
        }

        const bookId = bookLink.getAttribute('data-book')
        const chapterId = bookLink.getAttribute('data-chapter')
        bookLink.setAttribute('tabIndex', '0')
        bookLink.setAttribute('href', '#')

        // support "open in new tab"
        bookLink.addEventListener('mouseenter', (e: any) => {
          if (e) {
            const target = e.currentTarget as HTMLAnchorElement
            const url = new URL(window.location.href)
            const link = url.origin + url.pathname + '?b=' + bookId + '&c=' + chapterId
            target?.setAttribute('href', link)
          }
        })
      })
    }
  }, [currentLevel, dispatch, enableLawLinks, contentNode, contentRef, idrefLinkHandler, docsetLinkHandler])

  // randziffern
  React.useLayoutEffect(() => {
    // only applied for the top level
    if (contentRef.current && contentNode && currentLevel < 1) {
      const randziffern = contentRef.current!.querySelectorAll('.randziffer')
      randziffern.forEach((randziffer) => {
        const order = randziffer.getAttribute('order')
        const processed = randziffer.getAttribute('data-processed')
        if (order && processed !== 'true') {
          const orderNode = document.createTextNode(`[${order.replace(/^0+/, '')}]`)
          randziffer.appendChild(orderNode)
          randziffer.setAttribute('data-processed', 'true')
        }
      })
    }
  }, [contentNode, contentRef, currentLevel])

  // footnote tooltips
  const currentFootNotes = contentNode?.footNotes || parentFootNotes
  React.useLayoutEffect(() => {
    // only applied for the top level
    if (contentRef.current && contentNode && currentLevel <= 1) {
      const footnotes = contentRef.current!.querySelectorAll('.fussnote')
      const footnotesCatalogue = contentNode.footNotes // no reference to the parent needed -> only applied at level 1
      if (footnotesCatalogue) {
        footnotes.forEach((footnote) => {
          const footnoteId = footnote.getAttribute('data-id')
          if (footnoteId) {
            const footnoteHtml = footnotesCatalogue[footnoteId]
            tippy(footnote, {
              content: createFootnoteHtml(footnoteHtml),
              distance: 3,
              zIndex: 1,
              theme: 'light',
              flipOnUpdate: true,
              interactive: true,
              trigger: 'click',
            })
          }
        })
      }
    }
  }, [contentNode, contentRef, currentLevel])

  React.useLayoutEffect(() => {
    if (contentRef.current && currentLevel === 1) {
      const lindeComments = contentRef.current!.querySelectorAll('.linde_kommentar')
      lindeComments.forEach((lindeCommentNode) => {
        const contentNodes = lindeCommentNode.querySelectorAll('.content')
        contentNodes.forEach((contentNode: HTMLElement) => {
          contentNode.style.display = 'none'
        })

        // default open/closed state
        lindeCommentNode.classList.add('closed')

        const title = lindeCommentNode.firstChild?.firstChild
        title?.addEventListener('click', () => {
          // open/close classes
          const open = lindeCommentNode.classList.contains('opened')
          if (open) {
            lindeCommentNode.classList.remove('opened')
            lindeCommentNode.classList.add('closed')
          } else {
            lindeCommentNode.classList.remove('closed')
            lindeCommentNode.classList.add('opened')
          }

          // hide/show content
          contentNodes.forEach((contentNode: HTMLElement) => {
            const displayStyle = contentNode.style.display
            contentNode.style.display = displayStyle === 'none' ? 'block' : 'none'
          })
        })
      })
    }
  }, [contentRef, currentLevel])

  if (!contentNode) {
    return null
  }

  const enablePrintingIcon = contentNode.typ !== 'linde_kommentar'

  return (
    <div
      className={createClassNames(contentNode, currentLevel, enableLawLinks, printSelected)}
      ref={contentRef}
      onMouseEnter={() => setInFocus(true)}
      onMouseLeave={() => setInFocus(false)}
    >
      <div>
        {contentNode.titleHtml && (
          <Flex alignItems="baseline" nowrap fullWidth>
            <div className={createClassName('title')} dangerouslySetInnerHTML={{__html: contentNode.titleHtml}} />
            {enablePrintingIcon && (
              <div className="printing-icon" style={{position: 'relative'}}>
                <Box
                  width={30}
                  height={30}
                  pl={2}
                  textAlign="right"
                  cursorPointer
                  onClick={() => setPrintSelected(!printSelected)}
                >
                  <ThemedIcon
                    size={20}
                    verticalCenter
                    color={printSelected ? '#511d00' : '#ABABAB'}
                    hoverColor={printSelected ? '#511d00' : '#ABABAB'}
                    style={{
                      visibility: showPrintingIcon && contentNode.title ? 'visible' : 'hidden',
                      print: {display: 'none'},
                    }}
                  >
                    <PrinterIcon />
                  </ThemedIcon>
                </Box>
              </div>
            )}
          </Flex>
        )}
        {contentNode.contentHtml && (
          <div className={createClassName('content')} dangerouslySetInnerHTML={{__html: contentNode.contentHtml}} />
        )}
        <ContentPanelPrintFootnotes footNotes={currentFootNotes} footNoteIds={contentNode.footNoteIds} />
      </div>
      <ContentPanelChildren
        contentNode={contentNode}
        currentNodeId={currentNodeId}
        disablePrinting={disablePrinting}
        enableLawLinks={enableLawLinks}
        level={currentLevel}
        parentFootNotes={currentFootNotes}
        topNode={topNode}
        trackingInfo={trackingInfo}
      />
    </div>
  )
}
