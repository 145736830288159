import * as React from 'react'
import {LawContentPanel} from '../../law/components/content/LawContentPanel'
import {ResourceLoader} from './ResourceLoader'

interface Props {
  url: string
}

export const UIE_LawContentSample: React.FC<Props> = ({url}) => {
  return (
    <ResourceLoader url={url} asText render={(content) => <LawContentPanel content={content} navShown={false} />} />
  )
}
