import {AutoCompleteItem} from '../../commons-shared/search-input/SearchInput.types'
import {ContentNode} from '../../commons/types/ContentNode'
import {DocumentInfo} from '../../commons/types/DocumentInfo'
import {Link} from '../../commons/types/Link'
import {SearchResult} from '../../commons/types/SearchResult'
import {SourceInfo} from '../../commons/types/SourceInfo'
import {BaseStructureNode} from '../../commons/types/Structure'
import {TableOfContent} from '../../commons/types/TableOfContent'
import {KvFilters} from './kv.actions'

export enum KvActionKeys {
  LOAD_DOCUMENT = 'kv/LOAD_DOCUMENT',
  LOAD_DOCUMENT_SUCCESS = 'kv/LOAD_DOCUMENT_SUCCESS',
  LOAD_DOCUMENT_ERROR = 'kv/LOAD_DOCUMENT_ERROR',
  REDIRECT_LOAD_CONTENT = 'kv/REDIRECT_LOAD_CONTENT',
  EMPTY_SEARCH_RESULT = 'kv/EMPTY_SEARCH_RESULT',
  DOCUMENT_NOT_AVAILABLE = 'kv/DOCUMENT_NOT_AVAILABLE',
  SEARCH = 'kv/SEARCH',
  SEARCH_SUCCESS = 'kv/SEARCH_SUCCESS',
  SEARCH_MORE = 'kv/SEARCH_MORE',
  SEARCH_MORE_SUCCESS = 'kv/SEARCH_MORE_SUCCESS',
  SEARCH_AUTO_COMPLETE = 'kv/SEARCH_AUTO_COMPLETE',
  SEARCH_AUTO_COMPLETE_SUCCESS = 'kv/SEARCH_AUTO_COMPLETE_SUCCESS',
  LOAD_SOURCE_INFO = 'kv/LOAD_SOURCE_INFO',
  LOAD_SOURCE_INFO_SUCCESS = 'kv/LOAD_SOURCE_INFO_SUCCESS',
  LOAD_CHANGES = 'kv/LOAD_CHANGES',
  LOAD_CHANGES_MORE = 'kv/LOAD_CHANGES_MORE',
  LOAD_CHANGES_SUCCESS = 'kv/LOAD_CHANGES_SUCCESS',
  LOAD_NEWS = 'kv/LOAD_NEWS',
  LOAD_NEWS_MORE = 'kv/LOAD_NEWS_MORE',
  LOAD_NEWS_SUCCESS = 'kv/LOAD_NEWS_SUCCESS',
  LOAD_CONTENT = 'kv/LOAD_CONTENT',
  LOAD_CONTENT_SUCCESS = 'kv/LOAD_CONTENT_SUCCESS',
  SEND_FEEDBACK = 'kv/SEND_FEEDBACK',
  SEND_FEEDBACK_SUCCESS = 'kv/SEND_FEEDBACK_SUCCESS',
  SEND_FEEDBACK_ERROR = 'kv/SEND_FEEDBACK_ERROR',
  LOAD_DOCSET_INFO_SUCCESS = 'kv/LOAD_DOCSET_INFO_SUCCESS',
}

export interface KvState {
  readonly reader: {
    readonly structure: DocsetStructure | null
    readonly content: ContentNode | null
    readonly documentInfo: DocumentInfo | null
    readonly docSetName: string
    readonly emptySearch: boolean
    readonly kvParams: KvReaderQueryParams
    readonly loading: boolean
    readonly sourceInfo: SourceInfo | null
    readonly docSetInfo: DocSetInfo | null
  }
  readonly search: {
    readonly userQuery?: string
    readonly loading: boolean
    readonly page: number
    readonly filters: KvFilters | null
    readonly result: SearchResult | null
    readonly autoCompleteItems: KvAutoCompleteItem[]
  }
  readonly changes: {
    readonly items: KvChangesItem[]
    readonly currentPage: number
  }
  readonly news: {
    readonly result: SearchResult | null
    readonly currentPage: number
  }
  readonly content: {
    readonly toc: TableOfContent | null
    readonly contentNode: ContentNode | null
  }
  readonly feedbackSentSuccessfully: boolean | null
}

export enum AngArb {
  ANG = 'ANG',
  ARB = 'ARB',
}

export interface DocsetStructureNode extends BaseStructureNode<DocsetStructureNode> {
  validFrom: Date
  amended?: Date
  noContent?: boolean
  angArb?: AngArb[]
}

export interface DocsetStructure {
  main: DocsetStructureNode
  family?: DocsetStructureNode
}

export interface KvReaderQueryParams {
  paraId?: string
  validityDate?: Date
  userQuery?: string
  quickSearch?: string
}

export interface KvAutoCompleteItem extends AutoCompleteItem {
  docSetId: string
  highlightedName: string
}

export interface KvChangesItem {
  link: Link
  lastModified: Date
  angArb?: AngArb[]
}

export interface KvChanges {
  items: KvChangesItem[]
  start: number
  totalCount: number
}

export interface DocSetInfo {
  id: string
  name: string
  familyId?: string
  familyName?: string
  sectors?: string[]
  provinces?: string[]
  unions?: string[]
  angArb?: AngArb[]
  lastContentUpdate?: Date
  newsId?: string
  newsName?: string
  lohndatenEntries?: {
    'gilt-ab': Date
    'gilt-bis': Date
    link: {
      id: string
      name: string
      url: string
      type: string
    }
  }
}

export interface KvDocumentLookupInfo {
  readonly docSetId: string
  readonly timeSliceId: string
  readonly paragraphId?: string
}
