import querystring from 'query-string'
import {cleanUrlPathPart as c} from '../../commons/utils/urlUtils'
import {formatValidityDateApi} from '../utils/validityDateUtils'
import {KvFilters} from './kv.actions'
import {KvReaderQueryParams} from './kv.types'

export const kvQueryParams = {
  paraId: 'p',
  validityDate: 'v',
  sector: 's',
  userQuery: 'q',
  quickSearch: 'qs',
  extendedSearch: 'es',
  filterUnion: 'gew',
  filterProvince: 'bl',
  filterAngArb: 'av',
  filterContentModified: 'cm',
  filterContentModifiedFrom: 'from',
  filterContentModifiedTo: 'to',
  redirectFromDocument: 'from',
  redirectToIdRef: 'idref',
}

export const kvPathParams = {
  docSetId: 'docSetId',
  documentId: 'documentId',
  docSetName: 'docSetName',
  documentName: 'documentName',
  webcDocumentId: 'webcDocumentId',
  webcDocumentName: 'webcDocumentName',
}

export const KV_BASE_PATH = '/kvsystem'
const KV_BASE_PATH_KOLLEKTIVVERTRAG = KV_BASE_PATH + '/kollektivvertrag'
export const KV_LEGACY_BASE_PATH = '/kv-system'
export const KV_BASE_PATH_REDIRECT = `${KV_BASE_PATH}/r`

const createKvSearchQueryString = (userQuery?: string, filters?: KvFilters) => {
  if (userQuery === undefined && !filters) {
    return ''
  }

  const queryParams: {[key: string]: string} = {}
  if (userQuery !== undefined) {
    queryParams[kvQueryParams.userQuery] = userQuery
  }
  if (filters) {
    if (filters.ang_arb) {
      queryParams[kvQueryParams.filterAngArb] = filters.ang_arb
    }
    if (filters.contentModified) {
      queryParams[kvQueryParams.filterContentModified] = filters.contentModified
    }
    if (filters.union) {
      queryParams[kvQueryParams.filterUnion] = filters.union
    }
    if (filters.province) {
      queryParams[kvQueryParams.filterProvince] = filters.province
    }
    if (filters.validOn) {
      queryParams[kvQueryParams.validityDate] = filters.validOn
    }
    if (filters.sector) {
      queryParams[kvQueryParams.sector] = filters.sector
    }
  }
  const data = querystring.stringify(queryParams)
  return data.length > 0 ? `?${querystring.stringify(queryParams)}` : ''
}

export const kvPathOverview = (userQuery?: string, filter?: KvFilters) => {
  return `${KV_BASE_PATH}${createKvSearchQueryString(userQuery, filter)}`
}

const createKvDocumentQueryString = (params?: KvReaderQueryParams): string => {
  if (!params) {
    return ''
  }
  const queryParams: {[key: string]: string} = {}
  const {paraId, validityDate, userQuery, quickSearch} = params
  if (paraId) {
    queryParams[kvQueryParams.paraId] = paraId
  }
  if (validityDate) {
    queryParams[kvQueryParams.validityDate] = formatValidityDateApi(validityDate) || ''
  }
  if (userQuery) {
    queryParams[kvQueryParams.userQuery] = userQuery
  }
  if (quickSearch) {
    queryParams[kvQueryParams.quickSearch] = quickSearch
  }
  return `?${querystring.stringify(queryParams)}`
}

export const kvPathKvDocSetId = (docSetId: string, queryParams?: KvReaderQueryParams) => {
  return `${KV_BASE_PATH_KOLLEKTIVVERTRAG}/${docSetId}${createKvDocumentQueryString(queryParams)}`
}

export const kvPathDocument = (
  docSetId: string,
  documentId: string,
  docSetName: string,
  documentName: string,
  queryParams?: KvReaderQueryParams
) => {
  if (!documentId) {
    return `${KV_BASE_PATH_KOLLEKTIVVERTRAG}/${docSetId}`
  }
  return `${KV_BASE_PATH_KOLLEKTIVVERTRAG}/${docSetId},${documentId}/${c(docSetName)}/${c(
    documentName
  )}${createKvDocumentQueryString(queryParams)}`
}

export const kvPathIds = (docSetId: string, documentId: string, queryParams?: KvReaderQueryParams) => {
  return `${KV_BASE_PATH_KOLLEKTIVVERTRAG}/${docSetId},${documentId}${createKvDocumentQueryString(queryParams)}`
}

export const kvPathChanges = () => `${KV_BASE_PATH}/aenderungen`

export const kvPathNews = () => `${KV_BASE_PATH}/news`

export const kvPathContentPageId = (id: string) => `${KV_BASE_PATH}/c/${id}`
export const kvPathContentPage = (id: string, name: string) => `${KV_BASE_PATH}/c/${id}/${name}`

export const kvPathERechtDemoLogin = () => `${KV_BASE_PATH}/erecht-login-demo`

const e = encodeURIComponent

export const kvPathRedirect = (id: string, from: string, validityDate: Date | undefined) => {
  let link = `${KV_BASE_PATH_REDIRECT}?${e(kvQueryParams.redirectToIdRef)}=${e(id)}&${e(
    kvQueryParams.redirectFromDocument
  )}=${e(from)}`

  if (validityDate !== undefined) {
    const formattedValidityDate = formatValidityDateApi(validityDate)
    link += `&${kvQueryParams.validityDate}=${formattedValidityDate}`
  }

  return link
}
