import {renderRebootCss} from '@indoqa/style-system'
import {authLoginPath, authLogoutPath, baseCssProps, DevLoginPage, DevLogoutPage} from '@oegbv/ui-shared'
import * as React from 'react'
import {ThemeProvider, useFela} from 'react-fela'
import {Route, Switch} from 'react-router-dom'
import {ArContentResourcesPage} from '../ar/pages/ArContentResourcesPage'
import {ArPlusInfoPage} from '../ar/pages/ArPlusInfoPage'
import {ArSearchPage} from '../ar/pages/ArSearchPage'
import {
  arPathContentResources,
  arPathOverview,
  arPathParams,
  arPathPlusInfo,
  arPathSearchSimple,
} from '../ar/store/ar.paths'
import {authDetailsOverview} from '../auth-details/auth-details.paths'
import {AuthDetailsOverviewPage} from '../auth-details/pages/AuthDetailsOverviewPage'
import {BookEbookReaderPage} from '../book/pages/BookEbookReaderPage'
import {BookOverviewPage} from '../book/pages/BookOverviewPage'
import {BookWebBookIdReaderPage} from '../book/pages/BookWebBookIdReaderPage'
import {BookWebBookSlugReaderPage} from '../book/pages/BookWebBookSlugReaderPage'
import {
  bookPathEbook,
  bookPathOverview,
  bookPathParams,
  bookPathWebBookId,
  bookPathWebBookIdId,
  bookPathWebBookSlug,
  bookPathWebBookSlugId,
} from '../book/store/book.paths'
import {renderDigitalCSS} from '../commons/css-rules/DigitalCSSRules'
import {renderLawCSS} from '../commons/css-rules/LawCSSRules'
import {DigitalAkLoginPage} from '../digital/pages/DigitalAkLoginPage'
import {DigitalContentPage} from '../digital/pages/DigitalContentPage'
import {DigitalKvsLoginPage} from '../digital/pages/DigitalKVSLoginPage'
import {DigitalLoginPage} from '../digital/pages/DigitalLoginPage'
import {
  digitalAkLoginPage,
  digitalContentPage,
  digitalContentPageId,
  digitalKvsLoginPage,
  digitalLoginPage,
  digitalManzLoginRedirect,
  digitalPathOverview,
  digitalPathParams,
} from '../digital/store/digital.paths'
import {AppErrorBoundary} from '../error/components/AppErrorBoundary'
import {ErrorPage} from '../error/pages/ErrorPage'
import {KvChangesPage} from '../kv/pages/KvChangesPage'
import {KvContentPage} from '../kv/pages/KvContentPage'
import {KvERechtDemoLoginPage} from '../kv/pages/KvERechtDemoLoginPage'
import {KvLegacyUrlRedirectPage} from '../kv/pages/KvLegacyUrlRedirectPage'
import {KvNewsPage} from '../kv/pages/KvNewsPage'
import {KvOverviewPage} from '../kv/pages/KvOverviewPage'
import {KvReaderPage} from '../kv/pages/KvReaderPage'
import {KvRedirectPage} from '../kv/pages/KvRedirectPage'
import {
  kvPathChanges,
  kvPathContentPage,
  kvPathContentPageId,
  kvPathDocument,
  kvPathERechtDemoLogin,
  kvPathIds,
  kvPathKvDocSetId,
  kvPathNews,
  kvPathOverview,
  kvPathParams,
  KV_BASE_PATH_REDIRECT,
  KV_LEGACY_BASE_PATH,
} from '../kv/store/kv.paths'
import {LawGroupPage} from '../law/pages/LawGroupPage'
import {LawOverviewPage} from '../law/pages/LawOverviewPage'
import {LawReaderPage} from '../law/pages/LawReaderPage'
import {LawRedirectPage} from '../law/pages/LawRedirectPage'
import {
  lawPathGroup,
  lawPathGroupId,
  lawPathOverview,
  lawPathParams,
  lawPathReader,
  lawPathReaderIds,
  lawPathReaderLaw,
  lawPathReaderLawId,
  LAW_BASE_PATH_REDIRECT,
} from '../law/store/law.paths'
import {DigitalUIExplorer} from '../uie/pages/DigitalUIExplorer'
import {uiePathOverview} from '../uie/store/uie.paths'
import theme from './theme'

const paths = {
  arOverview: arPathOverview(),
  arSearch: arPathSearchSimple(`:${arPathParams.query}`),
  arContentResources: arPathContentResources(),
  arPathPlusInfo: arPathPlusInfo(),

  authDetailsOverview: authDetailsOverview(),

  digitalLanding: digitalPathOverview(),
  digitalLogin: digitalLoginPage(),
  digitalAkLogin: digitalAkLoginPage(),
  digitalKvsLogin: digitalKvsLoginPage(),
  digitalManzLogin: digitalManzLoginRedirect(),
  digitalContentId: digitalContentPageId(`:${digitalPathParams.contentNodeId}`),

  digitalContent: digitalContentPage(`:${digitalPathParams.contentNodeId}`, `${digitalPathParams.contentNodeName}`),

  lawOverview: lawPathOverview(),
  lawReader: lawPathReader(
    `:${lawPathParams.lawId}`,
    `:${lawPathParams.documentId}`,
    `:${lawPathParams.lawName}`,
    `:${lawPathParams.documentName}`
  ),
  lawReaderIds: lawPathReaderIds(`:${lawPathParams.lawId}`, `:${lawPathParams.documentId}`),
  lawReaderLaw: lawPathReaderLaw(`:${lawPathParams.lawId}`, `:${lawPathParams.lawName}`),
  lawReaderLawId: lawPathReaderLawId(`:${lawPathParams.lawId}`),
  lawGroup: lawPathGroup(`:${lawPathParams.groupId}`, `:${lawPathParams.groupName}`),
  lawGroupId: lawPathGroupId(`:${lawPathParams.groupId}`),
  lawRedirect: LAW_BASE_PATH_REDIRECT,

  kvLegacyBasePath: KV_LEGACY_BASE_PATH,
  kvOverview: kvPathOverview(),
  kvReaderDocsetId: kvPathKvDocSetId(`:${kvPathParams.docSetId}`),
  kvReaderIds: kvPathIds(`:${kvPathParams.docSetId}`, `:${kvPathParams.documentId}`),
  kvReader: kvPathDocument(
    `:${kvPathParams.docSetId}`,
    `:${kvPathParams.documentId}`,
    `:${kvPathParams.docSetName}`,
    `:${kvPathParams.documentName}`
  ),
  kvChanges: kvPathChanges(),
  kvNews: kvPathNews(),
  kvContentPageId: kvPathContentPageId(`:${kvPathParams.webcDocumentId}`),
  kvContentPage: kvPathContentPage(`:${kvPathParams.webcDocumentId}`, `:${kvPathParams.webcDocumentName}`),
  kvPathERechtDemoLogin: kvPathERechtDemoLogin(),
  kvPathRedirect: KV_BASE_PATH_REDIRECT,

  bookOverview: bookPathOverview(),
  bookEbook: bookPathEbook(`:${bookPathParams.bookId}`, `:${bookPathParams.bookTitle}`),
  bookWebbookSlug: bookPathWebBookSlugId(`:${bookPathParams.bookId}`),
  bookWebbookSlugId: bookPathWebBookSlug(`:${bookPathParams.bookId}`, `:${bookPathParams.chapterSlug}`),
  bookWebBookId: bookPathWebBookId(
    `:${bookPathParams.bookId}`,
    `:${bookPathParams.chapterId}`,
    `:${bookPathParams.bookTitle}`,
    `:${bookPathParams.chapterName}`
  ),
  bookWebBookIdId: bookPathWebBookIdId(`:${bookPathParams.bookId}`),

  devLogin: authLoginPath(),
  devLogout: authLogoutPath(),

  uie: uiePathOverview(),
}

const App: React.FC = () => {
  const {renderer} = useFela()
  React.useLayoutEffect(() => {
    renderRebootCss(renderer, baseCssProps(theme))
    // loadFonts(renderer)
    renderDigitalCSS(renderer, theme)
    renderLawCSS(renderer, theme)
  }, [renderer])
  return (
    <ThemeProvider theme={theme}>
      <AppErrorBoundary>
        <Switch>
          <Route exact path={paths.arSearch} component={ArSearchPage} />
          <Route exact path={paths.arOverview} component={ArSearchPage} />
          <Route exact path={paths.arContentResources} component={ArContentResourcesPage} />
          <Route exact path={paths.arPathPlusInfo} component={ArPlusInfoPage} />

          <Route exact path={paths.authDetailsOverview} component={AuthDetailsOverviewPage} />

          <Route exact path={paths.digitalLanding} component={DigitalContentPage} />
          <Route exact path={paths.digitalLogin} component={DigitalLoginPage} />
          <Route exact path={paths.digitalKvsLogin} component={DigitalKvsLoginPage} />
          <Route exact path={paths.digitalManzLogin} component={DigitalKvsLoginPage} />
          <Route exact path={paths.digitalAkLogin} component={DigitalAkLoginPage} />
          <Route exact path={paths.digitalContentId} component={DigitalContentPage} />
          <Route exact path={paths.digitalContent} component={DigitalContentPage} />

          <Route exact path={paths.lawOverview} component={LawOverviewPage} />
          <Route exact path={paths.lawReader} component={LawReaderPage} />
          <Route exact path={paths.lawReaderIds} component={LawReaderPage} />
          <Route exact path={paths.lawReaderLaw} component={LawReaderPage} />
          <Route exact path={paths.lawReaderLawId} component={LawReaderPage} />
          <Route exact path={paths.lawGroup} component={LawGroupPage} />
          <Route exact path={paths.lawGroupId} component={LawGroupPage} />
          <Route exact path={paths.lawRedirect} component={LawRedirectPage} />

          <Route exact={false} path={paths.kvLegacyBasePath} component={KvLegacyUrlRedirectPage} />
          <Route exact path={paths.kvOverview} component={KvOverviewPage} />
          <Route exact path={paths.kvReaderIds} component={KvReaderPage} />
          <Route exact path={paths.kvReaderDocsetId} component={KvReaderPage} />
          <Route exact path={paths.kvReader} component={KvReaderPage} />
          <Route exact path={paths.kvChanges} component={KvChangesPage} />
          <Route exact path={paths.kvNews} component={KvNewsPage} />
          <Route exact path={paths.kvContentPageId} component={KvContentPage} />
          <Route exact path={paths.kvContentPage} component={KvContentPage} />
          <Route exact path={paths.kvPathERechtDemoLogin} component={KvERechtDemoLoginPage} />
          <Route exact path={paths.kvPathRedirect} component={KvRedirectPage} />

          <Route exact path={paths.bookOverview} component={BookOverviewPage} />
          <Route exact path={paths.bookEbook} component={BookEbookReaderPage} />
          <Route exact path={paths.bookWebbookSlug} component={BookWebBookSlugReaderPage} />
          <Route exact path={paths.bookWebbookSlugId} component={BookWebBookSlugReaderPage} />
          <Route exact path={paths.bookWebBookId} component={BookWebBookIdReaderPage} />
          <Route exact path={paths.bookWebBookIdId} component={BookWebBookIdReaderPage} />

          <Route exact path={paths.devLogin} component={DevLoginPage} />
          <Route exact path={paths.devLogout} component={DevLogoutPage} />
          <Route path={paths.uie} component={DigitalUIExplorer} />

          <Route component={ErrorPage} />
        </Switch>
      </AppErrorBoundary>
    </ThemeProvider>
  )
}

export default App
